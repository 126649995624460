import React, { useRef, useState, useEffect } from "react"
import { Helmet } from 'react-helmet'
import "../global.css"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import HeadMetadata from "../components/HeadMetadata"
import Layout from '../layout/Layout'
import CallToAction from '../layout/CallToActionC'


export default () => {

  const img = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "carbonerasR1.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1800, quality:90) { ...GatsbyImageSharpFluid } } }
      img2: file(relativePath: { eq: "carbonerasR2.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
     
      img4: file(relativePath: { eq: "carboneras4.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img5: file(relativePath: { eq: "carboneras5.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img6: file(relativePath: { eq: "carboneras6.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img7: file(relativePath: { eq: "carbonerasR7.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img8: file(relativePath: { eq: "carbonerasR8.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
     
      img10: file(relativePath: { eq: "carbonerasR10.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      
      

          img1Res: file(relativePath: { eq: "carbonerasVer1.jpg" }) { 
            sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
          img2Res: file(relativePath: { eq: "carbonerasVer2.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth:1800, quality:90) { ...GatsbyImageSharpFluid } } }
          img3Res: file(relativePath: { eq: "carbonerasVer3.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img4Res: file(relativePath: { eq: "carbonerasVer4.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img5Res: file(relativePath: { eq: "carbonerasVer5.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img6Res: file(relativePath: { eq: "carbonerasVer6.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img7Res: file(relativePath: { eq: "carbonerasVer7.jpg" }) {
              sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img8Res: file(relativePath: { eq: "carbonerasVer8.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img9Res: file(relativePath: { eq: "carbonerasVer9.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
          img10Res: file(relativePath: { eq: "carbonerasVer10.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }         
    }
  `)

  return (
    <Layout className="overflow-hidden">
      <HeadMetadata title="Desarrollo Carboneras Reserva - Grupo Libera" />     
      
      <Helmet>
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/6452874.js"></script>
      </Helmet> 
      <section>
        <Img fluid={img.img1.sharp.fluid}  className="hidden lg:block" />  
        <Img fluid={img.img1Res.sharp.fluid}  className="lg:hidden" />  
      </section>  
      <a href="https://grupolibera.mx/pdf/Brochure_carboneras.pdf" target="_blank">
      <section>
        <Img fluid={img.img2.sharp.fluid}  className="hidden lg:block" />  
        <Img fluid={img.img2Res.sharp.fluid}  className="lg:hidden" />  
      </section>    
      </a>    
    
         
      <section>
          <Img fluid={img.img4.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img4Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <a href="https://www.google.com/maps/place/Carboneras+Sisal/@21.1215625,-89.9365012,17z/data=!3m1!4b1!4m5!3m4!1s0x85ff55f1d02056bd:0xd5239c24d98e6ac8!8m2!3d21.1215625!4d-89.9343125" target="blank">     
      <section>
          <Img fluid={img.img5.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img5Res.sharp.fluid} className="lg:hidden" />
      </section>
      </a>
      
      <section>
          <Img fluid={img.img6.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img6Res.sharp.fluid} className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img7.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img7Res.sharp.fluid} className="lg:hidden" />
      </section>
      <a href="https://carbonerasreserva.mx/disponibilidad.html" target="_blank">
      <section>
          <Img fluid={img.img8.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img8Res.sharp.fluid} className="lg:hidden" />
      </section>
      </a>
      <section>
          <Img fluid={img.img10.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img10Res.sharp.fluid} className="lg:hidden" />
      </section>

              
      <CallToAction
        phone="tel:9993462300"
        whatsapp="https://wa.me/+529993462300"
        email="mailto:info@grupolibera.mx"
      />

    </Layout >
  )
}

